<template>
  <v-container>
    <v-card-title primary-title class="margin-t-n2">
      <div>
        <div class="font-18">Comments</div>
        <span>
          {{ row.comments || 'None' }}
        </span>
      </div>
    </v-card-title>
  </v-container>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.$emit('row-expanded', this.props)
  },
}
</script>
