<template>
  <div class="nps-card" style="height: 120px; min-width: 120px">
    <v-layout column>
      <p class="nps-title">
        {{ title }}
      </p>
      <v-layout v-if="numberOfResponses > 0" align-center row class="nps-row">
        <p class="nps-value">
          {{ formattedValue }}
        </p>
        <p v-if="extraText" class="nps-extra-text">
          {{ extraText }}
        </p>
      </v-layout>
      <div class="number-of-ratings">
        <p style="margin-bottom: 0px">
          <span class="number">{{ numberOfRatings }}</span>
          ratings
        </p>
      </div>
    </v-layout>
  </div>
</template>

<script>
import { round } from '@/utils/round'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      default: 0,
    },
    extraText: {
      type: String,
      default: '',
    },
    numberOfResponses: {
      type: Number,
      default: 0,
    },
    isPlainText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedValue() {
      return `${round(this.value, 1)}${this.isPlainText ? '' : '%'}`
    },
    numberOfRatings() {
      return `${
        this.numberOfResponses > 0
          ? this.numberOfResponses.toLocaleString()
          : 'No'
      }`
    },
  },
}
</script>

<style lang="scss" scoped>
.nps-card {
  background-color: white;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  height: fit-content;
  min-height: 75px;
  max-width: 200px;
  padding: 17px 18px;
  position: relative;
  width: fit-content;

  .nps-title {
    font-size: 15px;
    font-weight: 600;
    margin: 0px;
    padding: 0px;
  }

  .nps-row {
    height: 20px;
    margin: 3px 0px 4px;

    .nps-value {
      color: black;
      font-size: 40px;
      font-weight: 400;
      color: $gray-dark;
      margin-bottom: 0px;
      margin-right: 10px;
    }

    .nps-extra-text {
      color: $gray-medium-light;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 0px;
      margin-right: 10px;
    }

    .no-ratings-text {
      color: black;
      font-size: 15px;
      font-weight: bold;
    }
  }

  .number-of-ratings {
    font-size: 13px;
    color: $gray-medium-light;
    margin-bottom: 0px;

    & .number {
      font-weight: 550;
    }
  }
}
</style>
