<template>
  <v-container fluid>
    <v-layout
      row
      style="
        margin-top: 25px;
        margin-bottom: -15px;
        padding-bottom: 0;
        height: 105px;
      "
    >
      <div xs2>
        <CRInput
          id="nps-response-type-filter"
          v-model="npsTypeId"
          item-text="label"
          item-value="id"
          item-key="id"
          :items="npsTypeList"
          solo
          flat
          type="autocomplete"
          clear-icon="close"
          browser-autocomplete="off"
          label="Response Type"
          :background-color="$cr.theme.white"
          color="primary"
          style="width: 200px"
          @change="refresh"
        />
      </div>
      <div xs2>
        <CRInput
          :id="`market-selection-filter`"
          v-model="marketId"
          item-text="marketName"
          item-value="marketId"
          item-key="marketId"
          :items="marketList"
          solo
          flat
          type="autocomplete"
          clear-icon="close"
          browser-autocomplete="off"
          label="Market"
          :background-color="$cr.theme.white"
          color="primary"
          style="width: 200px; margin-left: 15px"
          @change="refresh"
        />
      </div>
      <div xs2>
        <CRInput
          :id="`company-selection-filter`"
          v-model="companyId"
          solo
          flat
          type="text"
          clear-icon="close"
          browser-autocomplete="off"
          label="Company"
          :background-color="$cr.theme.white"
          color="primary"
          style="width: 200px; margin-left: 15px"
          @change="refresh"
        />
      </div>
      <div>
        <CRInput
          id="date-range-picker"
          v-model="dateRange"
          :items="dateRangeList"
          item-text="slotLabel"
          item-value="dateRange"
          item-key="dateRange"
          label="Date range"
          type="autocomplete"
          name="date-range-picker"
          style="width: 200px; margin-left: 15px"
          solo
          flat
          autocomplete="off"
          :background-color="$cr.theme.white"
          color="primary"
          @change="refresh"
        />
        <p class="date-range">{{ getDateRangeText }}</p>
      </div>
      <div>
        <CRInput
          v-if="dateRange === `Choose specific dates`"
          id="from-date-picker"
          v-model="fromDate"
          :classes="'white-input-slot'"
          label="From Date"
          type="date"
          name="from-date-picker"
          style="width: 200px; margin-left: 15px"
          :clearable="true"
          autocomplete="off"
          clear-icon="close"
          @change="refresh"
        />
      </div>
      <div>
        <CRInput
          v-if="dateRange === `Choose specific dates`"
          id="to-date-picker"
          v-model="toDate"
          :classes="'white-input-slot'"
          label="To Date"
          type="date"
          name="to-date-picker"
          style="width: 200px; margin-left: 15px"
          :clearable="true"
          autocomplete="off"
          clear-icon="close"
          @change="refresh"
        />
      </div>
    </v-layout>
    <v-layout row wrap space-between style="margin-bottom: 15px">
      <div v-for="npsCount in npsCounts" :key="`${npsCount.title}-nps-card`">
        <NPSCard
          :title="npsCount.title"
          :value="npsCount.value"
          :extra-text="npsCount.extraText"
          :is-plain-text="npsCount.isPlainText"
          :number-of-responses="npsCount.numberOfResponses"
          style="margin-left: 0; margin-right: 15px; width: 200px"
        />
      </div>
    </v-layout>
    <v-layout column sheet>
      <h1 class="page-header">Net Promoter Score</h1>
      <div class="d-flex justify-space-between">
        <div class="d-flex justify-end">
          <v-btn
            id="export-nps-responses"
            class="export-button"
            @click="exportNPS"
          >
            Export as .XLS
          </v-btn>
        </div>
      </div>
      <DataTable v-bind="tableProps" />
    </v-layout>
  </v-container>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import { DateTime } from 'luxon'
import { calculatedValues, noFutureDatesPredefined } from '@/utils/predefined'
import { deepClone } from '@/utils/deepClone'
import { currencyFilter } from '@/utils/currency'
import { truncate } from '@/utils/string'
import QuotesActionableColumn from '@/components/QuotesActionableColumn.vue'
import ReservationActionableColumn from '@/components/ReservationActionableColumn.vue'
import GenericActionableColumn from '@/components/GenericActionableColumn.vue'
import MultiNPSFilter from '@/components/MultiNPSFilter.vue'
import NPSCard from '@/components/NPSCard.vue'
import NPSResponseListDetail from '@/components/NPSResponseListDetail.vue'
import markets from '@/services/markets'
import nps from '@/services/nps'
import { mapActions } from 'vuex'
import { saveAs } from 'file-saver'

const columns = [
  {
    _t_id: 'f2bfe4fa',
    prop: 'quoteId',
    text: 'Quote ID',
    sort: true,
    filter: true,
    detail: false,
    type: 'number',
    sortProp: 'quoteId',
    filterProp: 'quoteId',
    filterType: 'eq',
    childMethod: 'and',
    component: QuotesActionableColumn,
    displayType: 'clickable-link',
  },
  {
    _t_id: '8009b579-a9f0-46eb-9332-2f561e4e450a',
    prop: 'reservationId',
    text: 'Reservation ID',
    sort: true,
    filter: true,
    detail: false,
    type: 'number',
    sortProp: 'reservationId',
    filterProp: 'reservationId',
    filterType: 'eq',
    childMethod: 'and',
    component: ReservationActionableColumn,
    displayType: 'clickable-link',
  },
  {
    _t_id: '50ef3662-f8f7-4f56-bd4a-3c00dbba7464',
    prop: 'companyId',
    text: 'Operator ID',
    sort: true,
    sortProp: 'companyId',
    type: 'number',
    method: 'eq',
    childMethod: 'and',
    predefined: deepClone(noFutureDatesPredefined),
    component: GenericActionableColumn,
    displayType: 'clickable-company',
  },
  {
    _t_id: 'f2bfe5c2',
    prop: ['customer/firstName', 'customer/lastName'],
    text: 'Customer',
    sort: true,
    filter: true,
    type: 'text',
    detail: false,
    sortProp: 'quote/customer/firstName',
    filterType: 'contains',
    filterProp: ['quote/customer/firstName', 'quote/customer/lastName'],
  },
  {
    _t_id: '04065690',
    prop: 'rating',
    text: 'Score',
    sort: true,
    filter: true,
    detail: false,
    type: 'number',
    sortProp: 'rating',
    filterType: 'eq',
    childMethod: 'and',
    predefined: [
      {
        text: 'Select Scores',
        controlType: 'default-repeat',
        refreshOnSelect: true,
        controls: [
          {
            id: '317abc7f-6cfb-4f43-b4c1-e7606048e501',
            text: 'Select Scores',
            filterType: 'contains',
            component: MultiNPSFilter,
          },
        ],
      },
    ],
  },
  {
    _t_id: '64943509-f63e-4778-9bcc-e7f446474b4e',
    prop: 'truncatedComments',
    text: 'Comments',
    action: 'NPS_DETAIL',
    sort: false,
    filter: true,
    detail: false,
    type: 'text',
    filterProp: 'comments',
    filterType: 'contains',
    childMethod: 'and',
    component: QuotesActionableColumn,
    displayType: 'action-item-black',
  },
  {
    _t_id: 'f1cc036e4-bd8b-4ae1-aa86-cb284bc7099b',
    prop: ['bookedBy/firstName', 'bookedBy/lastName'],
    text: 'Booked By',
    sort: true,
    filter: true,
    type: 'text',
    detail: false,
    sortProp: 'quote/bookedBy/firstName',
    filterType: 'contains',
    filterProp: ['quote/bookedBy/firstName', 'quote/bookedBy/lastName'],
  },
  {
    _t_id: 'aa3db626-7948-4cf3-92ca-1c78a0dd1eb8',
    prop: 'marketName',
    text: 'Market',
    sort: true,
    filter: false,
    sortProp: 'quote/firstStop/address/nearestMarket/marketName',
    filterProp: 'quote/firstStop/address/nearestMarket/marketName',
    detail: false,
    type: 'text',
    filterType: 'eq',
    childMethod: 'and',
  },
  {
    _t_id: '909e11bb-7572-4b2a-9b05-7db106381252',
    prop: 'pickupDate',
    text: 'Pickup Date',
    computedText: (item) => DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
    sort: true,
    sortProp: 'updatedOn',
    type: 'date',
    method: 'and',
    childMethod: 'and',
    predefined: deepClone(noFutureDatesPredefined),
  },
  {
    _t_id: 'f9d4f648',
    prop: 'updatedOn',
    text: 'Submitted On',
    computedText: (item) => DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
    sort: true,
    sortProp: 'updatedOn',
    type: 'date',
    method: 'and',
    childMethod: 'and',
    predefined: deepClone(noFutureDatesPredefined),
  },
  {
    _t_id: 'ee1503ce-110c-4d26-b079-a540f6533f71',
    prop: 'amount',
    text: 'Amount',
    sort: true,
    filter: false,
    sortProp: 'quote.amount',
    filterProp: 'quote.amount',
    detail: false,
    type: 'text',
    filterType: 'eq',
    childMethod: 'and',
    computedText: (item) => currencyFilter(item),
  },
  {
    _t_id: 'f47c45e9-0689-4350-bfa7-33ccc1edc6f2',
    prop: 'npsTypeLabel',
    text: 'Response Type',
    sort: true,
    filter: false,
    sortProp: 'npsTypeId',
    filterProp: 'npsTypeId',
    detail: false,
    type: 'text',
    filterType: 'eq',
    childMethod: 'and',
  },
]

export default {
  components: {
    DataTable,
    NPSCard,
  },
  metaInfo() {
    return {
      title: 'NPS Responses',
    }
  },
  data() {
    return {
      debounce: undefined,
      filters: () => [],
      sorts: () => [],
      user: {},
      itemsPerPage: 10,
      page: 1,
      isAdmin: false,
      parentFilter: null,
      grandparentFilter: null,
      fromDate: '',
      toDate: '',
      marketId: null,
      companyId: null,
      npsTypeId: null,
      marketList: [],
      npsTypeList: [],
      dateRangeList: [
        { id: 0, slotLabel: 'All Time' },
        { id: 1, slotLabel: 'Choose specific dates' },
        { id: 2, slotLabel: 'Last Week' },
        { id: 3, slotLabel: 'Last Two Weeks' },
        { id: 4, slotLabel: 'Last Month' },
        { id: 5, slotLabel: 'Last Quarter' },
        { id: 6, slotLabel: 'Last Semester' },
        { id: 7, slotLabel: 'Last Year' },
      ],
      dateRanges: {
        'All Time': null,
        'Last Week': 7,
        'Last Two Weeks': 14,
        'Last Month': 30,
        'Last Quarter': 91,
        'Last Semester': 182,
        'Last Year': 365,
      },
      dateRange: null,
      npsTypeMap: {},
      npsTypeCount: 0,
      npsCounts: [],
      uiFilters: [],
      tableProps: {
        enableExport: false,
        enableColumnConfig: false,
        enableSavedViews: false,
        total: 0,
        tableId: 'nps_responses_table_view',
        currentPage: 1,
        perPage: 10,
        list: [],
        sort: this.sort,
        changePage: this.changePage,
        addFilter: this.addFilter,
        removeFilter: this.removeFilter,
        addNewEnabled: false,
        setSort: this.setSort,
        detailKeyId: 'npsResponseId',
        isDetailed: false,
        shareFilters: this.receiveFilters,
        shareSorts: this.receiveSorts,
        loading: true,
        isAdmin: this.isAdmin,
        columns,
        calculatedValues,
        detail: NPSResponseListDetail,
      },
    }
  },
  computed: {
    computedFromDate() {
      if (this.fromDate) {
        return DateTime.fromISO(this.fromDate).toFormat('yyyy-MM-dd HH:mm:ss')
      }
      return '2018-01-01 12:00:00'
    },
    computedToDate() {
      if (this.toDate) {
        return DateTime.fromISO(this.toDate)
          .plus({ days: 1 })
          .toFormat('yyyy-MM-dd HH:mm:ss')
      }
      return DateTime.local().plus({ days: 1 }).toFormat('yyyy-MM-dd HH:mm:ss')
    },
    getDateRangeText() {
      if (
        !this.dateRange ||
        this.dateRange === 'Choose specific dates' ||
        this.dateRange === 'All Time'
      ) {
        return ''
      }
      return `From ${this.fromDate} to ${this.toDate}`
    },
  },
  watch: {
    computedFromDate() {
      this.getNPSCounts()
      this.refresh()
    },
    computedToDate() {
      this.getNPSCounts()
      this.refresh()
    },
    marketId() {
      this.getNPSCounts()
      this.refresh()
    },
    companyId() {
      this.getNPSCounts()
      this.refresh()
    },
    npsTypeId() {
      this.getNPSCounts()
      this.refresh()
    },
    npsTypeCount() {
      this.processResponsesForDisplay()
      this.refresh()
    },
    dateRange() {
      this.setDateRange()
      this.refresh()
    },
  },
  async mounted() {
    await this.getNPSCounts()
    await this.getMarketList()
    await this.getNPSTypes()
  },
  methods: {
    ...mapActions({
      getNPSResponseTypes: 'types/getNPSResponseTypes',
    }),
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (immediate) {
        this.getTable()
      } else {
        this.debounce = setTimeout(this.getTable, 500)
      }
    },
    async getMarketList() {
      const marketsData = await markets.tableView({
        pageSize: -1,
      })
      this.marketList = marketsData?.data?.resultList || []
      this.marketList.sort((a, b) => (a.marketName > b.marketName ? 0 : -1))
      this.marketList.unshift({ marketId: 0, marketName: 'Show All' })
      this.marketList = this.marketList.map((item) => {
        return { ...item, slotLabel: item.marketName }
      })
    },
    async getNPSTypes() {
      const npsResponseTypes = await this.getNPSResponseTypes()
      this.npsTypeList = npsResponseTypes?.data || []
      this.npsTypeList.unshift({ id: 0, label: 'Show All' })
      this.npsTypeList.forEach((type) => {
        this.npsTypeMap[type.id] = type.label
      })
      this.npsTypeList = this.npsTypeList.map((item) => {
        return { ...item, slotLabel: item.label }
      })
      this.npsTypeCount = this.npsTypeMap?.count
    },
    async getNPSCounts() {
      const npsResponse = await nps.npsAverage({
        fromDate: this.computedFromDate,
        toDate: this.computedToDate,
        marketId: this.marketId,
        operatorId: this.companyId,
        npsTypeId: this.npsTypeId,
      })

      const npsData = npsResponse.data

      const promoterPercentage =
        npsData?.responseCount != null || npsData.responseCount != 0
          ? (100 * npsData?.promoterCount) / npsData?.responseCount
          : null
      const passivePercentage =
        npsData?.responseCount != null || npsData.responseCount != 0
          ? (100 * npsData?.passiveCount) / npsData?.responseCount
          : null
      const detractorPercentage =
        npsData?.responseCount != null || npsData.responseCount != 0
          ? (100 * npsData?.detractorCount) / npsData?.responseCount
          : null

      this.npsCounts = [
        {
          title: 'NPS',
          value: promoterPercentage - detractorPercentage,
          isPlainText: true,
          numberOfResponses: npsData?.responseCount,
        },
        {
          title: 'Promoters (10-9)',
          value: promoterPercentage,
          numberOfResponses: npsData?.promoterCount,
        },
        {
          title: 'Passives (8-7)',
          value: passivePercentage,
          numberOfResponses: npsData?.passiveCount,
        },
        {
          title: 'Detractors (6-1)',
          value: detractorPercentage,
          numberOfResponses: npsData?.detractorCount,
        },
      ]
    },
    async setUIFilters() {
      const submittedOnFromDateFilter = {
        column: {
          _t_id: 'edda24ef-ce4a-48e4-8d6c-a89b40355ce9',
          filterProp: 'updatedOn',
          type: 'date',
          filterType: 'gte',
        },
        value: this.computedFromDate,
      }

      const submittedOnToDateFilter = {
        column: {
          _t_id: 'ff3a5b75-c03f-47c6-a3e4-8e2b9b9bbf50',
          filterProp: 'updatedOn',
          type: 'date',
          filterType: 'lte',
        },
        value: this.computedToDate,
      }

      const marketIdFilter = {
        column: {
          _t_id: '7e0c12e8-b483-4fac-89a7-f4e22838999d',
          filterProp: 'quote.firstStop.address.nearestMarketId',
          type: 'number',
          filterType: 'eq',
        },
        value: this.marketId,
      }

      const companyIdFilter = {
        column: {
          _t_id: 'e70fc184-41ca-4c8f-b068-202b20ccce1e',
          filterProp: 'companyId',
          type: 'number',
          filterType: 'eq',
        },
        value: this.companyId,
      }

      const npsTypeIdFilter = {
        column: {
          _t_id: 'b928f613-e5ce-4d0c-896f-f447d576024b',
          filterProp: 'npsTypeId',
          type: 'number',
          filterType: 'eq',
        },
        value: this.npsTypeId,
      }

      for (let filter of this.uiFilters) {
        this.filters.remove(filter)
      }

      const filters = []
      if (this.fromDate) {
        filters.push(submittedOnFromDateFilter)
      }
      if (this.toDate) {
        filters.push(submittedOnToDateFilter)
      }
      if (this.marketId) {
        filters.push(marketIdFilter)
      }
      if (this.companyId) {
        filters.push(companyIdFilter)
      }
      if (this.npsTypeId) {
        filters.push(npsTypeIdFilter)
      }
      for (let filter of filters) {
        let parentFilter = this.filters.createParent('and')
        await this.filters.add(parentFilter, filter)
        this.uiFilters.push(filter)
        this.uiFilters.push(parentFilter)
      }
    },
    async getTable() {
      await this.setUIFilters()

      const sorts = this.sorts.asQueryParams()
      const filters = this.filters.asQueryParams()
      const params = {
        sorts,
        filters,
        pageSize: this.itemsPerPage,
        page: this.currentPage,
      }
      this.tableProps.loading = true
      const npsResponsesResponse = await nps.npsResponseListView(params)
      const npsResponses = npsResponsesResponse.data
      this.tableProps.perPage = this.itemsPerPage
      this.tableProps.currentPage = this.currentPage
      this.tableProps.list = npsResponses.resultList
      this.tableProps.total = npsResponses.count
      this.tableProps.loading = false
      this.processResponsesForDisplay()
    },
    processResponsesForDisplay() {
      this.tableProps.list.forEach((response) => {
        response.npsTypeLabel = this.npsTypeMap[response.npsTypeId]
        if (response.comments) {
          response.truncatedComments = truncate(response.comments, 15, true)
        }
        if (response.rating === 0) {
          response.rating = '0'
        }
      })
    },
    setDateRange() {
      const today = new Date()

      if (this.dateRange in this.dateRanges) {
        const daysAgo = this.dateRanges[this.dateRange]
        if (daysAgo !== null) {
          const fromDate = new Date(
            today.getTime() - daysAgo * 24 * 60 * 60 * 1000
          )
          this.fromDate = fromDate.toISOString().split('T')[0]
        } else {
          this.fromDate = ''
        }
        this.toDate = today.toISOString().split('T')[0]
      }
    },
    async exportNPS() {
      await this.setUIFilters()

      const sorts = this.sorts.asQueryParams()
      const filters = this.filters.asQueryParams()
      const params = {
        sorts,
        filters,
      }

      this.$emit('update:loading', true)
      try {
        const exportData = await nps.exportNPSResponses(params)
        await saveAs(exportData.data, 'nps-responses.xls')
      } finally {
        this.$emit('update:loading', false)
      }
    },
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
    changePage(pagination) {
      this.currentPage = pagination.page
      this.itemsPerPage = pagination.rowsPerPage
      this.refresh()
    },
  },
}
</script>

<style lang="scss" scoped>
.v-btn-toggle--selected {
  box-shadow: none;
}

.export-button {
  max-width: 150px;
  min-height: 40px;
  border: 1px solid $primary;
  color: $primary;
  background-color: $white !important;
}

.cr-input {
  label {
    display: block;
    margin-bottom: 4px;
  }

  .select {
    width: 100%;
    max-width: 100%;
    height: 42px;

    select {
      width: 100%;
      max-width: 100%;
      height: 42px;
      color: $gray-medium-light;
      background: $secondary;
      border: 2px solid $secondary;
      outline: none;
      box-shadow: none;

      &:hover {
        border-color: $gray-light;
      }

      &:focus,
      &:focus:hover {
        border-color: $primary;
      }
    }

    option {
      color: $black;
    }

    option:first-child {
      color: $gray-medium-light;
    }
  }
}

.radio-group {
  flex: none;
  margin: 0;
  padding: 0;

  .v-radio {
    margin: 0 18px;
  }
}

.progress-bar {
  position: absolute;
  left: 0;
  top: -16px;
}

.date-range {
  font-size: 12px;
  margin-left: 27px;
  margin-top: -8px;
  padding-bottom: 25px;
}
</style>
