<template>
  <CRSelect
    :id="`${id}-multi-nps-filter-select-score`"
    v-model="npsSelections"
    :items="npsItems"
    chips
    class="radius-selector"
    flat
    hide-details
    item-text="label"
    item-value="rating"
    multiple
    solo
    @input="setFilter"
  />
</template>

<script>
import { deepClone } from '@/utils/deepClone'
import { authComputed } from '@/state/helpers'

export default {
  props: {
    filters: {
      type: Object,
      default: () => undefined,
    },
    control: {
      type: Object,
      default: () => undefined,
    },
    column: {
      type: Object,
      default: () => undefined,
    },
    addFilter: {
      type: Function,
      default: () => () => true,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      npsSelections: [],
      npsFilter: {
        column: {
          _t_id: '4f632c5a-7c30-4801-b711-81fa9dd27926',
          prop: 'rating',
          type: 'number',
          filterType: 'eq',
        },
      },
      activeNpsFilter: undefined,
    }
  },
  computed: {
    ...authComputed,
    npsItems() {
      let npsList = []
      for (let i = 0; i <= 10; i++) {
        npsList.push(i)
      }
      return npsList
    },
  },
  async mounted() {},
  methods: {
    findFilter(filter) {
      return filter && this.filters.find(filter)
    },
    setFilter(e) {
      if (this.findFilter(this.activeNpsFilter)) {
        this.filters.remove(this.activeNpsFilter)
      }
      if (this.npsSelections.length) {
        const grandParent = this.filters.createParent('and')
        this.grandParentFilter = grandParent
        this.addNpsFilter()
        this.addFilter()
      }
      this.$emit('make-dirty-request')
      this.addFilter()
    },
    addNpsFilter() {
      const cloneNpsSelections = [].concat(this.npsSelections)
      const newFilter = deepClone(this.npsFilter)
      newFilter.hideOnFilterBar = true
      newFilter.value = cloneNpsSelections.join(' ')
      this.activeNpsFilter = newFilter
      newFilter.stepParent = this.column._t_id
      this.filters.add(this.grandParentFilter, newFilter)
    },
  },
}
</script>

<style lang="scss" scoped>
.radius-selector {
  width: 200px !important;
  color: $primary;
  background-color: $white;
  border: 1px solid $primary;
  border-radius: 4px;
}

.radius-selector::v-deep {
  .v-input__control {
    min-height: 42px;
  }

  .theme--light.v-chip {
    color: $white !important;
    background: $primary !important;
  }
}
</style>
